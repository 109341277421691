import NavBar from '../Components/NavBar';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import MarkdownView from 'react-showdown';


const Project = () => {

    const [project, setProject] = useState(null);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, "projects", id);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setProject(docSnap.data());
                } else {
                    setError(error);
                }

            } catch (error) {
                console.error("Error getting document:", error);
            }
        }

        fetchData();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <NavBar />
            <div id="Project" className="section">
                <div id="projectPageImageSection">
                    <div id="projectPageImage" style={project ? { backgroundImage: 'url("' + project.image_header + '")' } : { backgroundImage: 'url("")' }}></div>
                    <p id="projectPageCredits">{project ? project.header_credits : ''}</p>
                </div>
                <div id="projectPageTextSection">
                    <h1 id="projectPageTextTitle">{project ? project.name : ''}</h1>
                    <p id="projectPageTextAbstract">{project ? project.abstract : ''}</p>
                    <div id="projectPageText">
                        <MarkdownView
                            markdown={project ? project.text : ''}
                            options={{ tables: true, emoji: true }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Project;