import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return (
        <>
            <div id="navBar">
                <h2 id="navBarWork" className="linkNav">
                    <NavLink to="/">→ <span>Work</span></NavLink>
                </h2>
                <div id='navBarHome' className='logo' style={{ backgroundImage: 'url("./Logo.png")' }} >
                </div>
                <h2 id="navBarAbout" className="linkNav">
                    <NavLink to="/About">→ <span>About</span></NavLink>
                </h2>
            </div>
            <div id="navBarBottom">
                <h2 id="navBarBottomWork" className="linkNavBottom">
                    <NavLink to="/">Work</NavLink>
                </h2>
                <h2 id="navBarBottomAbout" className="linkNavBottom">
                    <NavLink to="/About">About</NavLink>
                </h2>
            </div>
        </>
    );
};

export default NavBar;