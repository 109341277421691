import NavBar from '../Components/NavBar';
import React, { useEffect, useState } from 'react';
import MarkdownView from 'react-showdown';
import { AboutData } from '../Components/GetData.jsx'

const About = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);

    const fetchData = async () => {
        setLoading(true)

        const data = await AboutData()

        setData([...data])
        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <NavBar />
            <section id="About" className="section">
                <div id="photo" style={{
                    backgroundImage: 'url(' + data.map(about => (
                        about.bio_image
                    ))
                        + ')'
                }}></div>
                <div id="aboutText">{data.length > 0 && data.map(about => (
                    <MarkdownView
                        markdown={about.about_bio}
                        options={{ tables: true, emoji: true }}
                        key={about}
                    />
                ))}</div>
                <p id="credits">Website developed and designed by <a href="https://www.instagram.com/sylathas/">Sylathas</a></p>
            </section>
        </>
    );
};

export default About;