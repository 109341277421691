import NavBar from '../Components/NavBar';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { ProjectData } from '../Components/GetData.jsx'

const Work = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);

    const fetchData = async () => {
        setLoading(true)

        const data = await ProjectData()

        const sortedArray = data.sort((a, b) => b.created_on.seconds - a.created_on.seconds);

        setData([...sortedArray])
        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <NavBar />
            <section id="Work" className="section">
                <div id="grid">
                    {data.length > 0 && data.map(project => (
                        <Project key={project.id} project={project} />
                    ))}
                </div>
            </section>
        </>
    );
};

const Project = (props) => {
    const { project } = props;
    const navigate = useNavigate();

    return (
        <div className="project" onClick={() => navigate(`${project.id}`, { projectInfo: { project } })}>
            <div className='projectImageContainer'>
                <div className='projectImage' style={{ backgroundImage: 'url("' + project.image_header + '")' }}></div>
            </div>
            <h1 className='projectTitle'> {project.name} </h1>
            <p className='projectDetails'> {project.details} </p>
            <p className='projectType'> {project.types.map(type => <span>{type.slice(1) + ', '}</span>)} </p>
        </div>
    );
}

export default Work;