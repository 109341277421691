import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBcwssSxb3zUzcZ1aiwTia7OwyAu8Tx_gU",
    authDomain: "matildemanicardi-a7eb3.firebaseapp.com",
    databaseURL: "https://matildemanicardi-a7eb3-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "matildemanicardi-a7eb3",
    storageBucket: "matildemanicardi-a7eb3.appspot.com",
    messagingSenderId: "894680331419",
    appId: "1:894680331419:web:e3207f62e16636b532c23e"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
